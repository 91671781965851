import React from 'react';
import { Skeleton } from 'antd';
import { ThreatDashboardProps } from './types';

const LazyThreatDashboard = React.lazy(() => import('./ThreatDashboard'));

const skeletonFallback = (
	<div style={{ marginLeft: '20px', paddingBottom: '4px' }}>
		<Skeleton.Input active size="small" />
	</div>
);

export const AsyncLazyThreatDashboard: React.FC<ThreatDashboardProps> = props => (
	<React.Suspense fallback={skeletonFallback}>
		<LazyThreatDashboard {...props} />
	</React.Suspense>
);
